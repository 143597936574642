<template>
	<div class="table-container">
		<v-overlay
			:opacity="0.15"
			:value="loader"
			absolute
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						indeterminate
						size="40"
						width="6"
					/>
				</v-col>
			</v-row>
		</v-overlay>
		<v-card elevation="6" class="success">
			<v-card-text>
				<div class="error-container" v-if="loadingError">
					<v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
				</div>
				<v-row>
					<v-col>
						<div class="label text--darken-1">{{ label }}</div>
						<v-data-table
							:headers="headers"
							:items-per-page="-1"
							:items="accountsReceivable"
							hide-default-footer
							class="mt-3 appic-table-light specification-table"
							dense
							id="accountsReceivableTable"
						>
							<template v-slot:item.Contract.title="{item}">
								<div class="text-no-wrap font-weight-bold">{{ item.Contract.title }}</div>
							</template>
							<template v-slot:item.Invoice.date="{item}">
								<div class="text-no-wrap">{{ formatDate(item.Invoice.date) }}</div>
							</template>
							<template v-slot:item.Contract.etddate="{item}">
								<div class="text-no-wrap">{{ formatDate(item.Contract.etddate) }}</div>
							</template>
							<template v-slot:item.Contract.etadate="{item}">
								<div class="text-no-wrap">{{ formatDate(item.Contract.etadate) }}</div>
							</template>
							<template v-slot:item.CustomerPayment.outstanding="{ item }">
								<div class="text-end">{{ numberFormat(item.CustomerPayment.outstanding, '0,0.00') }}</div>
							</template>
							<template v-slot:item.CustomerPayment.duedate="{item}">
								<div :class="'text-no-wrap font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')">{{ formatDate(item.CustomerPayment.duedate) }}</div>
							</template>
							<template v-slot:item.CustomerPayment.age1to30="{ item }">
								<div :class="'text-no-wrap text-end font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')" v-if="item.CustomerPayment.age1to30 > 0">{{ numberFormat(item.CustomerPayment.age1to30, '0,0.00') }}</div>
							</template>
							<template v-slot:item.CustomerPayment.age31to60="{ item }">
								<div :class="'text-no-wrap text-end font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')" v-if="item.CustomerPayment.age31to60 > 0">{{ numberFormat(item.CustomerPayment.age31to60, '0,0.00') }}</div>
							</template>
							<template v-slot:item.CustomerPayment.age60beyond="{ item }">
								<div :class="'text-no-wrap text-end font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')" v-if="item.CustomerPayment.age60beyond > 0">{{ numberFormat(item.CustomerPayment.age60beyond, '0,0.00') }}</div>
							</template>
							<template v-slot:item.CustomerPayment.overdue="{ item }">
								<div :class="'text-no-wrap text-end font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')" v-if="item.CustomerPayment.overdue > 0">{{ numberFormat(item.CustomerPayment.overdue, '0,0') }}</div>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import {formatDate, formatDateShort, numberFormat} from "Helpers/helpers";
import {api} from "Api";
export default {
	name: "DueInvoices",
	props: ['conditions'],
    data() {
		return {
			// currentExpectedPayOut: 0,
			errorMessage: null,
			// maximumExpectedPayOut: 0,
			label: 'Invoices due in the current month',
			loader: true,
			loadingError: false,
			accountsReceivable: []
        }
    },
    computed: {
		headers() {
			return [
				{
					id: 1,
					text: this.$t('message.contract'),
					value: 'Contract.title',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 2,
					text: this.$t('message.type'),
					value: 'Contract.type',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 3,
					text: this.$t('message.buyer'),
					value: 'Customer.name',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 4,
					text: this.$t('message.invoice'),
					value: 'Invoice.no',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 5,
					text: this.$t('message.date'),
					value: 'Invoice.date',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 6,
					text: this.$t('message.terms'),
					value: 'Contract.type_insurance',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 7,
					text: this.$t('message.buyerTerms'),
					value: 'CustomerPayment.status',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 8,
					text: this.$t('message.etd'),
					value: 'Contract.etddate',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 9,
					text: this.$t('message.eta'),
					value: 'Contract.etadate',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 10,
					text: this.$t('message.cur'),
					value: 'Currency.code',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
					sortable: true,
					searchable: true
				},
				{
					id: 11,
					text: this.$t('message.balance'),
					value: 'CustomerPayment.outstanding',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 12,
					text: this.$t('message.due'),
					value: 'CustomerPayment.duedate',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 13,
					text: '1-30' + this.$t('message.days'),
					value: 'CustomerPayment.age1to30',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 14,
					text: '31-60' + this.$t('message.days'),
					value: 'CustomerPayment.age31to60',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 15,
					text: '>60' + this.$t('message.days'),
					value: 'CustomerPayment.age60beyond',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				},
				{
					id: 16,
					text: this.$t('message.overdue'),
					value: 'CustomerPayment.overdue',
					class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left',
					sortable: true,
					searchable: true
				}
			]
		}
    },
	methods: {
		formatDate,
		formatDateShort,
		formatThisNumber (value,format) {
			return numberFormat(value,format)
		},
		loadReport(conditions) {
			return new Promise((resolve, reject) => {
				api
					.get('/sparkline/buyer-aging', {
						params: {
							conditions: conditions ? conditions : []
						}
					})
					.then((response) => {
						if(response.data.status == 'success') {
							resolve(response.data.data)
						} else {
							reject(response.data.status)
						}
					})
					.catch((error) => {
						reject(error)
					});
			})
		},
		numberFormat,
		overDue (date) {
			const now = new Date()
			const testDate = new Date(date)
			if(now.getTime() > testDate.getTime()) return true
			return false
		}
	},
	mounted() {
		this.loadReport(this.conditions)
			.then((data) => {
				this.accountsReceivable = data
				this.loader = false
			})
			.catch((error) => {
				this.loader = false
				this.loadingError = true
				this.errorMessage = error
			})
	}
}
</script>

<style scoped>
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep th.force-text-right {
    text-align: right !important;
}
.table-container {
	position: relative;
}
.blue-grey {
	background-color: #1E3C5A !important;
}
.label {
    font-weight: bold;
    font-size: 1.25em;
}
</style>