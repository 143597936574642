var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('v-overlay',{attrs:{"opacity":0.15,"value":_vm.loader,"absolute":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-card',{staticClass:"success",attrs:{"elevation":"6"}},[_c('v-card-text',[(_vm.loadingError)?_c('div',{staticClass:"error-container"},[_c('v-alert',{staticClass:"mt-3 mb-0",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))])],1):_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticClass:"label text--darken-1"},[_vm._v(_vm._s(_vm.label))]),_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.accountsReceivable,"hide-default-footer":"","dense":"","id":"accountsReceivableTable"},scopedSlots:_vm._u([{key:"item.Contract.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap font-weight-bold"},[_vm._v(_vm._s(item.Contract.title))])]}},{key:"item.Invoice.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.Invoice.date)))])]}},{key:"item.Contract.etddate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.Contract.etddate)))])]}},{key:"item.Contract.etadate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.Contract.etadate)))])]}},{key:"item.CustomerPayment.outstanding",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.outstanding, '0,0.00')))])]}},{key:"item.CustomerPayment.duedate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:'text-no-wrap font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.formatDate(item.CustomerPayment.duedate)))])]}},{key:"item.CustomerPayment.age1to30",fn:function(ref){
var item = ref.item;
return [(item.CustomerPayment.age1to30 > 0)?_c('div',{class:'text-no-wrap text-end font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.age1to30, '0,0.00')))]):_vm._e()]}},{key:"item.CustomerPayment.age31to60",fn:function(ref){
var item = ref.item;
return [(item.CustomerPayment.age31to60 > 0)?_c('div',{class:'text-no-wrap text-end font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.age31to60, '0,0.00')))]):_vm._e()]}},{key:"item.CustomerPayment.age60beyond",fn:function(ref){
var item = ref.item;
return [(item.CustomerPayment.age60beyond > 0)?_c('div',{class:'text-no-wrap text-end font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.age60beyond, '0,0.00')))]):_vm._e()]}},{key:"item.CustomerPayment.overdue",fn:function(ref){
var item = ref.item;
return [(item.CustomerPayment.overdue > 0)?_c('div',{class:'text-no-wrap text-end font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.overdue, '0,0')))]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }